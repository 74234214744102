<template>
  <div class="questionnaire">
    <div>
      <div
        v-if="currentQuestion"
        class="question-section"
        :key="currentQuestion.id"
      >
        <span class="progress-text"
          >{{ currentIndex + 1 }}/{{ questions.length }}</span
        >
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressWidth }"></div>
        </div>

        <div class="question">
          <h3 class="question-title">
            {{ currentQuestion.subject || currentQuestion.title }}
          </h3>
          <!-- 单选 -->
          <div v-if="currentQuestion.type === 'radio'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="radio"
                :id="'option-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="handleAnswer"
                class="radio-input"
              />
              <label
                :for="'option-' + currentQuestion.id + '-' + index"
                class="radio-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 多选 -->
          <div v-if="currentQuestion.type === 'checkbox'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="checkbox"
                :id="'checkbox-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="handleCheckboxChange"
                class="checkbox-input"
              />
              <label
                :for="'checkbox-' + currentQuestion.id + '-' + index"
                class="checkbox-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 下拉框 -->
          <div
            v-if="currentQuestion.type === 'select'"
            class="select-container"
          >
            <select
              v-model="answers[currentQuestion.id]"
              @change="handleAnswer"
              class="custom-select"
            >
              <option
                v-for="(option, index) in parsedOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="navigation">
          <button
            @click="prevQuestion"
            :disabled="currentIndex === 0"
            class="nav-button"
          >
            上一题
          </button>
          <!-- 当为最后一题时显示提交按钮 -->
          <button
            v-if="currentIndex === questions.length - 1"
            @click="submitAnswers"
            class="submit-button"
          >
            提交
          </button>
        </div>
      </div>
      <div v-else class="loading">
        <p>加载中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      questions: [], // 所有题目
      currentIndex: 0, // 当前题目索引
      answers: {}, // 用户选择的答案
      show144: "true",
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
    progressWidth() {
      if (this.questions.length === 0) {
        return "0%";
      }
      return ((this.currentIndex + 1) / this.questions.length) * 100 + "%";
    },
    parsedOptions() {
      // 动态解析选项
      if (this.currentQuestion.answer) {
        try {
          const parsed = JSON.parse(this.currentQuestion.answer);
          return parsed.value || [];
        } catch (e) {
          console.error("无法解析 answer 字段：", this.currentQuestion.answer);
          return [];
        }
      } else if (this.currentQuestion.item) {
        return this.currentQuestion.item.map((option) => option.name);
      }
      return [];
    },
  },
  methods: {
    // 用于单选和下拉框：保存答案后直接进入下一题
    handleAnswer() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
      this.nextQuestion();
    },
    // 多选题点击处理（仅当勾选时立即跳转）
    handleCheckboxChange(event) {
      if (event.target.checked) {
        const currentAnswer = this.answers[this.currentQuestion.id] || [];
        const clickedOption = event.target.value;
        // 对特殊题目（例如索引为 4 或 31）的处理
        if (this.currentIndex === 31 || this.currentIndex === 4) {
          if (clickedOption === "无不适感觉") {
            this.$set(this.answers, this.currentQuestion.id, ["无不适感觉"]);
          } else if (clickedOption === "以上均无") {
            this.$set(this.answers, this.currentQuestion.id, ["以上均无"]);
          } else {
            const updatedAnswers = currentAnswer.filter(
              (option) => option !== "无不适感觉" && option !== "以上均无"
            );
            updatedAnswers.push(clickedOption);
            this.$set(this.answers, this.currentQuestion.id, updatedAnswers);
          }
        } else {
          // 非特殊多选题直接保存当前点击的选项（仅允许选择一个选项）
          this.$set(this.answers, this.currentQuestion.id, [clickedOption]);
        }
        localStorage.setItem("answers", JSON.stringify(this.answers));
        this.nextQuestion();
      }
    },
    // 加载题目
    async fetchQuestions() {
      const userId = this.$route.query.userId;
      console.log(userId);
      try {
        const response = await fetch(
          "https://assess.eapchina.net/getQuestions889"
        );
        const data = await response.json();
        this.questions = Object.values(data.data).flat();
        this.questions.forEach((question) => {
          if (!question.type) {
            console.warn(`题目 ID ${question.id} 缺少 type 字段`);
          }
          // 初始化多选题答案为数组
          if (
            question.type === "checkbox" &&
            !Array.isArray(this.answers[question.id])
          ) {
            this.$set(this.answers, question.id, []);
          }
        });
        const part10Questions = this.questions.filter((q) => q.part === 11);
        const otherQuestions = this.questions.filter((q) => q.part !== 11);
        this.questions = [...otherQuestions, ...part10Questions];
        this.loadProgress(); // 加载保存的进度
      } catch (error) {
        console.error("加载题目失败：", error);
      }
    },
    // 加载本地缓存的进度
    loadProgress() {
      const savedAnswers = localStorage.getItem("answers");
      if (savedAnswers) {
        this.answers = JSON.parse(savedAnswers);
      }
      // 回显“其他”内容到输入框（如果有）
      this.inputvalue = this.answers[`${this.currentQuestion.id}_other`] || "";
      // 定位到第一个未回答的题目
      this.currentIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];
        if (question.type === "checkbox") {
          return !answer || answer.length === 0;
        }
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }
        return answer === null || answer === undefined || answer === "";
      });
      if (this.currentIndex === -1) {
        this.currentIndex = this.questions.length - 1;
      }
    },
    // 跳转到下一题（不再调用 saveAnswer 防止重复保存）
    nextQuestion() {
      const currentAnswer = this.answers[this.currentQuestion.id];
      if (
        (this.currentQuestion.type === "radio" && !currentAnswer) ||
        (this.currentQuestion.type === "checkbox" &&
          (!currentAnswer || currentAnswer.length === 0)) ||
        (this.currentQuestion.type === "select" &&
          (currentAnswer === null ||
            currentAnswer === undefined ||
            currentAnswer === ""))
      ) {
        Toast.fail("请选择选项后再继续！");
        return;
      }
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
        this.inputvalue =
          this.answers[`${this.currentQuestion.id}_other`] || "";
        localStorage.setItem("answers", JSON.stringify(this.answers));
      }
    },
    // 上一题操作
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.inputvalue =
          this.answers[`${this.currentQuestion.id}_other`] || "";
        localStorage.setItem("answers", JSON.stringify(this.answers));
      }
    },
    // 提交答案
    async submitAnswers() {
      const unansweredIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];
        if (question.type === "checkbox") {
          return !answer || answer.length === 0;
        }
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }
        return answer === null || answer === undefined || answer === "";
      });
      if (unansweredIndex !== -1) {
        this.currentIndex = unansweredIndex;
        Toast.fail(`请完成第 ${unansweredIndex + 1} 题后再提交！`);
        return;
      }
      const daan = {};
      for (const [questionId, answer] of Object.entries(this.answers)) {
        const question = this.questions.find((q) => q.id == questionId);
        if (!question) continue;
        if (question.lbassessment_id) {
          const selectedOption = question.item.find(
            (option) => option.name === answer || option.id == answer
          );
          if (selectedOption) {
            daan[`lb${question.lbassessment_id}-${question.id}`] =
              selectedOption.id.toString();
          }
        } else {
          const voteKey = `vote-${questionId}`;
          if (Array.isArray(answer)) {
            daan[voteKey] = answer.join(",");
          } else {
            daan[voteKey] = answer;
          }
        }
      }
      daan["userId"] = localStorage.getItem("userId");
      console.log("提交的答案：", daan);
      Toast.loading({
        message: "正在计算结果",
        forbidClick: true,
        duration: 0,
      });
      try {
        const response = await fetch(
          "https://assess.eapchina.net/saveTheAnswer4XBZT",
          {
            method: "POST",
            body: JSON.stringify(daan),
          }
        );
        if (!response.ok) {
          throw new Error(`提交失败，状态码：${response.status}`);
        }
        const result = await response.json();
        Toast.success("提交成功");
        localStorage.setItem("voteLogId", result.voteLogId);
        setTimeout(() => {
          this.$router.push("/CP/xinlijiankang/answerOver");
        }, 1000);
        localStorage.removeItem("answers");
      } catch (error) {
        Toast.fail("提交失败");
      }
    },
  },
  created() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.questionnaire {
  font-family: "Arial", sans-serif;
  max-width: 720px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* background: #fff; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.title {
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.progress-bar {
  position: relative;
  background: #e0e0e0;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  background: #76c7c0;
  height: 100%;
}

.progress-text {
  font-size: 0.9em;
  margin-top: 8px;
  display: block;
  text-align: center;
}

.question-title {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #444;
  line-height: 30px;
  margin-bottom: 30px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-input,
.checkbox-input {
  margin-right: 10px;
}

.radio-label,
.checkbox-label {
  font-size: 1em;
  color: #555;
}

.select-container {
  margin-top: 10px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #555;
  background: #f9f9f9;
}

.navigation {
  margin-top: 20px;
  text-align: center;
}

.nav-button,
.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #76c7c0;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled),
.submit-button:hover {
  background-color: #5ba9a1;
}

@media (max-width: 768px) {
  .questionnaire {
    padding: 15px;
  }

  .title {
    font-size: 1.5em;
  }

  .nav-button,
  .submit-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
  .introduction {
    text-align: center;
    padding: 20px;
    max-width: 720px;
    margin: auto;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
  }

  .introduction .content {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    color: #444;
  }

  .start-button {
    padding: 12px 24px;
    font-size: 1.1em;
    border: none;
    border-radius: 6px;
    background-color: #76c7c0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .start-button:hover {
    background-color: #5ba9a1;
  }
}
.qita {
  height: 0.3rem;
  width: 50%;
}
.qita2 {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 5px;
  border: #ccc 1px solid;
}
</style>
